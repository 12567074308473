import React from 'react';
import '../../components/base.scss';
import './FAQ.css';
// import { Link } from 'react-router-dom';

// import './Navbar/Navbar.scss';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const TalofaNavbar = () => {
  return (
    <div className="navbar">
      <Navbar collapseOnSelect bg="dark" expand="lg" variant="dark">
        <Navbar.Brand href="#home">
          <Nav.Link href="/">
            <picture>
              <source srcSet="../Home/logoTransparent.png" type="image/webp" />
              <source srcSet="../Home/logoTransparent.png" type="image/jpeg" />
              <img className="talofa_logo" src="../Home/logoTransparent.png" />
            </picture>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Nav.Link href="/faq">FAQ</Nav.Link>
            <Nav.Link href="/privacy">Privacy</Nav.Link>
            <Nav.Link target="_blank" href="https://presskit.talofagames.com/">
              Presskit
            </Nav.Link>

            <Nav.Link href="https://discord.gg/runlegends" target="_blank">
              <Button className="discord-btn">
                {' '}
                <img className="discord-logo" src="../Home/discord-logo.png" />
                Join Discord Community
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default function FAQG2() {
  return (
    <div className="faq">
      <TalofaNavbar />

      <div className="background-container">
        <h1>FAQ</h1>
        <p>Note: Please only use official links, double check before clicking links, and beware of scams!</p>
        <div className="faq-container">
          <div className="left-column">
            
            <div className="faq-section">
              <div>
                <h2>Having Trouble with Step Tracking? </h2>
                <p>We pull your steps from either Apple HealthKit for iOS or Health Connect and Google Fit for Android. </p> 
                <p> For Apple HealthKit, make sure in your Settings you've allowed Where They Live: Monster Walk to read your Steps and that you have Motion Permissions allowed. </p> 
                <p> For Android, you'll need to have Health Connect and Google Fit installed. Each step is shown below, but if you have more questions, <a href="mailto:help@talofagames.com">email us at help@talofagames.com</a>.  
                </p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/1.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/2.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/3.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/4.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/5.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/6.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/7.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/8.png" /></p>
                 <p><img className="bullshark-faq-img" src="../HealthConnect/9.png" /></p>
                <p>You're all set! </p>
                <p>If you are still running into issues getting backgrounded steps to work, <a href="https://support.google.com/fit/answer/12830119?hl=en-GB">read this article from Google</a> or please contact us through our <a href="https://discord.gg/6zePBvKd2X">Discord Community</a> or <a href="mailto:help@talofagames.com">email us at help@talofagames.com</a> </p>
              </div>
            </div>

            <div className="faq-section">
              <div>
                <h2>How do I get the game?</h2>
                <p>
                  {' '}
                  Download the game by <a href="https://apps.apple.com/app/where-they-live-monster-walk/id6482576236">clicking here for iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.talofagames.stephunter&pli=1">clicking here for Android</a> and you’ll be directed to the direct download links!

                  If you do not already have access to the official Discord, you can <a href="https://discord.gg/6zePBvKd2X">click here</a> to join.

                </p>
              </div>
            </div>
             <div className="faq-section">
              <div>
                <h2>What state is the game in?</h2>
                <p>
                  As we're in early development, game content may change with updates.Thanks for your support as we improve the experience!"
                </p>
              </div>
            </div>
            <div className="faq-section">
              <div>
                <h2>How much does it cost to play? </h2>
                <p>Where They Live: Monster Walk is a free to play experience and a free to download mobile game.

                  You do not need to spend money in the game to unlock features.

                  There is an in-game store where you can purchase items that enhance the experience.
                </p>
              </div>
            </div>

            <div className="faq-section">
              <div>
                <h2>What happens if I have issues with the game, or want to delete my account?</h2>
                <p>Join our <a href="https://discord.gg/6zePBvKd2X">Discord</a> to get in
                  contact with the development team.

                  You can also <a href="mailto:help@talofagames.com">email us at help@talofagames.com</a>.

                  We are the most responsive over Discord, so if you need to urgently reach us, that is the best place to do so.
                </p>
              </div>
            </div>
            <div className="faq-section">
              <div>
                <h2>How do I learn more about the game?</h2>
                <p>Check out our social media pages to learn more about the game!

                    We are on <a href="https://twitter.com/TalofaGames?lang=en">Twitter</a>, <a href="https://www.facebook.com/RunLegends">Facebook</a>, <a href="https://www.instagram.com/playrunlegends/">Instagram</a>, <a href="https://www.tiktok.com/@runlegends">Tiktok</a>, <a href="https://www.youtube.com/@playrunlegends">Youtube</a> and <a href="https://medium.com/@TalofaGames">Medium</a>.

                    We are the most responsive over Discord, so if you need to urgently reach us, that is the best place to do so.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
